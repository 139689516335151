:root {
      --lume-g-color-opacity-disabled: 0.5;
      --lume-g-color-yellow-95: #fbf3e0;
      --lume-g-color-yellow-90: #f9e3b6;
      --lume-g-color-yellow-80: #fcc003;
      --lume-g-color-yellow-70: #e4a201;
      --lume-g-color-yellow-65: #d79304;
      --lume-g-color-yellow-60: #ca8501;
      --lume-g-color-yellow-50: #a86403;
      --lume-g-color-yellow-40: #8c4b02;
      --lume-g-color-yellow-30: #6f3400;
      --lume-g-color-yellow-20: #4f2100;
      --lume-g-color-yellow-15: #2e2204;
      --lume-g-color-yellow-10: #281202;
      --lume-g-color-green-95: #ebf7e6;
      --lume-g-color-green-90: #cdefc4;
      --lume-g-color-green-80: #91db8b;
      --lume-g-color-green-70: #45c65a;
      --lume-g-color-green-65: #41b658;
      --lume-g-color-green-60: #3ba755;
      --lume-g-color-green-50: #2e844a;
      --lume-g-color-green-40: #396547;
      --lume-g-color-green-30: #194e31;
      --lume-g-color-green-20: #1c3326;
      --lume-g-color-green-15: #0c2912;
      --lume-g-color-green-10: #071b12;
      --lume-g-color-teal-95: #def9f3;
      --lume-g-color-teal-90: #acf3e4;
      --lume-g-color-teal-80: #04e1cb;
      --lume-g-color-teal-70: #01c3b3;
      --lume-g-color-teal-65: #03b4a7;
      --lume-g-color-teal-60: #06a59a;
      --lume-g-color-teal-50: #0b827c;
      --lume-g-color-teal-40: #056764;
      --lume-g-color-teal-30: #024d4c;
      --lume-g-color-teal-20: #023434;
      --lume-g-color-teal-15: #072825;
      --lume-g-color-teal-10: #071b12;
      --lume-g-color-blue-95: #eef4ff;
      --lume-g-color-blue-90: #d8e6fe;
      --lume-g-color-blue-80: #aacbff;
      --lume-g-color-blue-70: #78b0fd;
      --lume-g-color-blue-65: #57a3fd;
      --lume-g-color-blue-60: #1b96ff;
      --lume-g-color-blue-50: #0176d3;
      --lume-g-color-blue-40: #0b5cab;
      --lume-g-color-blue-30: #014486;
      --lume-g-color-blue-20: #032d60;
      --lume-g-color-blue-15: #03234d;
      --lume-g-color-blue-10: #001639;
      --lume-g-color-indigo-95: #f1f3fb;
      --lume-g-color-indigo-90: #e0e5f8;
      --lume-g-color-indigo-80: #bec7f6;
      --lume-g-color-indigo-70: #9ea9f1;
      --lume-g-color-indigo-65: #8e9bef;
      --lume-g-color-indigo-60: #7f8ced;
      --lume-g-color-indigo-50: #5867e8;
      --lume-g-color-indigo-40: #3a49da;
      --lume-g-color-indigo-30: #2f2cb7;
      --lume-g-color-indigo-20: #321d71;
      --lume-g-color-indigo-15: #1f0974;
      --lume-g-color-indigo-10: #200647;
      --lume-g-color-neutral-100: #ffffff;
      --lume-g-color-neutral-95: #f3f3f3;
      --lume-g-color-neutral-90: #e5e5e5;
      --lume-g-color-neutral-80: #c9c9c9;
      --lume-g-color-neutral-70: #aeaeae;
      --lume-g-color-neutral-65: #a0a0a0;
      --lume-g-color-neutral-60: #939393;
      --lume-g-color-neutral-50: #747474;
      --lume-g-color-neutral-40: #5c5c5c;
      --lume-g-color-neutral-30: #444444;
      --lume-g-color-neutral-20: #2e2e2e;
      --lume-g-color-neutral-15: #242424;
      --lume-g-color-neutral-10: #181818;
      --lume-g-color-red-95: #fef1ee;
      --lume-g-color-red-90: #feded8;
      --lume-g-color-red-80: #feb8ab;
      --lume-g-color-red-70: #fe8f7d;
      --lume-g-color-red-65: #fe7765;
      --lume-g-color-red-60: #fe5c4c;
      --lume-g-color-red-50: #ea001e;
      --lume-g-color-red-40: #ba0517;
      --lume-g-color-red-30: #8e030f;
      --lume-g-color-red-20: #640103;
      --lume-g-color-red-15: #4a0c04;
      --lume-g-color-red-10: #300c01;
      --lume-g-color-border-primary: var(--lume-g-color-neutral-80);
      --lume-g-color-border-disabled: var(--lume-g-color-neutral-90);
      --lume-g-color-border-focus: var(--lume-g-color-blue-50);
      --lume-g-color-border-secondary: var(--lume-g-color-neutral-70);
      --lume-g-color-border-tertiary: var(--lume-g-color-neutral-60);
      --lume-g-color-border-inverse: var(--lume-g-color-neutral-100);
      --lume-g-color-border-divider: var(--lume-g-color-neutral-80);
      --lume-g-color-border-brand: var(--lume-g-color-indigo-70);
      --lume-g-color-border-accent: var(--lume-g-color-teal-70);
      --lume-g-color-border-informative: var(--lume-g-color-blue-70);
      --lume-g-color-border-positive: var(--lume-g-color-green-70);
      --lume-g-color-border-attentive: var(--lume-g-color-yellow-70);
      --lume-g-color-border-negative: var(--lume-g-color-red-60);
      --lume-g-color-container-primary: var(--lume-g-color-neutral-100);
      --lume-g-color-container-primary-hover: var(--lume-g-color-neutral-95);
      --lume-g-color-container-primary-active: var(--lume-g-color-neutral-90);
      --lume-g-color-container-secondary: var(--lume-g-color-neutral-95);
      --lume-g-color-container-secondary-hover: var(--lume-g-color-neutral-90);
      --lume-g-color-container-secondary-active: var(--lume-g-color-neutral-80);
      --lume-g-color-container-tertiary: var(--lume-g-color-neutral-90);
      --lume-g-color-container-tertiary-hover: var(--lume-g-color-neutral-80);
      --lume-g-color-container-tertiary-active: var(--lume-g-color-neutral-70);
      --lume-g-color-container-selected: var(--lume-g-color-indigo-95);
      --lume-g-color-container-selected-hover: var(--lume-g-color-indigo-90);
      --lume-g-color-container-selected-active: var(--lume-g-color-indigo-80);
      --lume-g-color-container-brand: var(--lume-g-color-indigo-50);
      --lume-g-color-container-brand-hover: var(--lume-g-color-indigo-40);
      --lume-g-color-container-brand-active: var(--lume-g-color-indigo-30);
      --lume-g-color-container-brand-secondary: var(--lume-g-color-indigo-95);
      --lume-g-color-container-accent: var(--lume-g-color-teal-50);
      --lume-g-color-container-accent-hover: var(--lume-g-color-teal-40);
      --lume-g-color-container-accent-active: var(--lume-g-color-teal-30);
      --lume-g-color-container-accent-secondary: var(--lume-g-color-teal-95);
      --lume-g-color-container-informative: var(--lume-g-color-blue-60);
      --lume-g-color-container-informative-hover: var(--lume-g-color-blue-50);
      --lume-g-color-container-informative-active: var(--lume-g-color-blue-40);
      --lume-g-color-container-informative-secondary: var(--lume-g-color-blue-95);
      --lume-g-color-container-positive: var(--lume-g-color-green-50);
      --lume-g-color-container-positive-hover: var(--lume-g-color-green-40);
      --lume-g-color-container-positive-active: var(--lume-g-color-green-30);
      --lume-g-color-container-positive-secondary: var(--lume-g-color-green-95);
      --lume-g-color-container-attentive: var(--lume-g-color-yellow-80);
      --lume-g-color-container-attentive-hover: var(--lume-g-color-yellow-70);
      --lume-g-color-container-attentive-active: var(--lume-g-color-yellow-60);
      --lume-g-color-container-attentive-secondary: var(--lume-g-color-yellow-95);
      --lume-g-color-container-negative: var(--lume-g-color-red-50);
      --lume-g-color-container-negative-hover: var(--lume-g-color-red-40);
      --lume-g-color-container-negative-active: var(--lume-g-color-red-30);
      --lume-g-color-container-negative-secondary: var(--lume-g-color-red-95);
      --lume-g-color-container-disabled: var(--lume-g-color-neutral-90);
      --lume-g-color-icon-primary: var(--lume-g-color-neutral-20);
      --lume-g-color-icon-disabled: var(--lume-g-color-neutral-65);
      --lume-g-color-icon-selected: var(--lume-g-color-indigo-40);
      --lume-g-color-icon-secondary: var(--lume-g-color-neutral-30);
      --lume-g-color-icon-tertiary: var(--lume-g-color-neutral-40);
      --lume-g-color-icon-inverse: var(--lume-g-color-neutral-100);
      --lume-g-color-icon-brand: var(--lume-g-color-indigo-40);
      --lume-g-color-icon-accent: var(--lume-g-color-teal-40);
      --lume-g-color-icon-informative: var(--lume-g-color-blue-40);
      --lume-g-color-icon-positive: var(--lume-g-color-green-40);
      --lume-g-color-icon-attentive: var(--lume-g-color-yellow-40);
      --lume-g-color-icon-negative: var(--lume-g-color-red-40);
      --lume-g-color-surface-container-primary: var(--lume-g-color-neutral-100);
      --lume-g-color-surface-container-secondary: var(--lume-g-color-neutral-95);
      --lume-g-color-surface-container-tertiary: var(--lume-g-color-neutral-90);
      --lume-g-color-surface-container-inverse: var(--lume-g-color-blue-20);
      --lume-g-color-surface-container-brand: var(--lume-g-color-indigo-95);
      --lume-g-color-surface-container-accent: var(--lume-g-color-teal-95);
      --lume-g-color-surface-container-informative: var(--lume-g-color-blue-95);
      --lume-g-color-surface-container-positive: var(--lume-g-color-green-95);
      --lume-g-color-surface-container-attentive: var(--lume-g-color-yellow-95);
      --lume-g-color-surface-container-negative: var(--lume-g-color-red-95);
      --lume-g-color-surface-primary: var(--lume-g-color-neutral-100);
      --lume-g-color-surface-secondary: var(--lume-g-color-neutral-95);
      --lume-g-color-text-primary: var(--lume-g-color-neutral-20);
      --lume-g-color-text-disabled: var(--lume-g-color-neutral-65);
      --lume-g-color-text-selected: var(--lume-g-color-indigo-40);
      --lume-g-color-text-secondary: var(--lume-g-color-neutral-30);
      --lume-g-color-text-tertiary: var(--lume-g-color-neutral-40);
      --lume-g-color-text-inverse: var(--lume-g-color-neutral-100);
      --lume-g-color-text-placeholder: var(--lume-g-color-neutral-70);
      --lume-g-color-text-link: var(--lume-g-color-blue-50);
      --lume-g-color-text-link-hover: var(--lume-g-color-blue-40);
      --lume-g-color-text-link-active: var(--lume-g-color-blue-30);
      --lume-g-color-text-brand: var(--lume-g-color-indigo-40);
      --lume-g-color-text-brand-on-container: var(--lume-g-color-neutral-100);
      --lume-g-color-text-accent: var(--lume-g-color-teal-40);
      --lume-g-color-text-accent-on-container: var(--lume-g-color-neutral-100);
      --lume-g-color-text-informative: var(--lume-g-color-blue-40);
      --lume-g-color-text-informative-on-container: var(--lume-g-color-blue-15);
      --lume-g-color-text-positive: var(--lume-g-color-green-40);
      --lume-g-color-text-positive-on-container: var(--lume-g-color-neutral-100);
      --lume-g-color-text-attentive: var(--lume-g-color-yellow-40);
      --lume-g-color-text-attentive-on-container: var(--lume-g-color-yellow-15);
      --lume-g-color-text-negative: var(--lume-g-color-red-40);
      --lume-g-color-text-negative-on-container: var(--lume-g-color-neutral-100);
      --lume-g-font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
      --lume-g-font-family-code: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
      --lume-g-font-weight-normal: 400;
      --lume-g-font-weight-semibold: 600;
      --lume-g-font-weight-bold: 700;
      --lume-g-font-scale-neg-2: 0.75rem;
      --lume-g-font-scale-neg-1: 0.875rem;
      --lume-g-font-size-base: 1rem;
      --lume-g-font-scale-1: 1.25rem;
      --lume-g-font-scale-2: 1.5rem;
      --lume-g-font-scale-3: 1.75rem;
      --lume-g-font-scale-4: 2rem;
      --lume-g-font-scale-5: 2.5rem;
      --lume-g-font-scale-6: 3rem;
      --lume-g-font-scale-7: 3.5rem;
      --lume-g-font-scale-8: 4rem;
      --lume-g-kinetics-transition-duration: 0.15s;
      --lume-g-kinetics-transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
      --lume-g-outline-focus-ring: 2px solid transparent;
      --lume-g-outline-offset-focus-ring: 0px;
      --lume-g-radius-border-1: 0.25rem;
      --lume-g-radius-border-2: 0.5rem;
      --lume-g-radius-border-3: 0.75rem;
      --lume-g-radius-border-4: 1.25rem;
      --lume-g-radius-border-circle: 100%;
      --lume-g-radius-border-pill: 999px;
      --lume-g-shadow-focus-ring: 0px 0px 0px 2px var(--lume-g-color-neutral-100), 0px 0px 0px 4px var(--lume-g-color-blue-50);
      --lume-g-shadow-level-1: 0px 0px 1px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.09), 0px 3px 2px 0px rgba(0, 0, 0, 0.05), 0px 6px 2px 0px rgba(0, 0, 0, 0.01);
      --lume-g-shadow-level-2: 0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 7px 7px 0px rgba(0, 0, 0, 0.09), 0px 16px 10px 0px rgba(0, 0, 0, 0.05), 0px 28px 11px 0px rgba(0, 0, 0, 0.01);
      --lume-g-shadow-level-3: 0px 3px 8px 0px rgba(0, 0, 0, 0.10), 0px 14px 14px 0px rgba(0, 0, 0, 0.09), 0px 31px 19px 0px rgba(0, 0, 0, 0.05), 0px 55px 22px 0px rgba(0, 0, 0, 0.01);
      --lume-g-shadow-level-4: 0px 5px 10px 0px rgba(0, 0, 0, 0.10), 0px 18px 18px 0px rgba(0, 0, 0, 0.09), 0px 41px 25px 0px rgba(0, 0, 0, 0.05), 0px 73px 29px 0px rgba(0, 0, 0, 0.01);
      --lume-g-shadow-level-5: 0px 5px 12px 0px rgba(0, 0, 0, 0.10), 0px 21px 21px 0px rgba(0, 0, 0, 0.09), 0px 47px 28px 0px rgba(0, 0, 0, 0.05), 0px 84px 34px 0px rgba(0, 0, 0, 0.01);
      --lume-g-sizing-border-1: 1px;
      --lume-g-sizing-border-2: 2px;
      --lume-g-sizing-border-3: 3px;
      --lume-g-sizing-border-4: 4px;
      --lume-g-spacing-1: 0.125rem;
      --lume-g-spacing-2: 0.25rem;
      --lume-g-spacing-3: 0.375rem;
      --lume-g-spacing-4: 0.5rem;
      --lume-g-spacing-5: 0.75rem;
      --lume-g-spacing-6: 1rem;
      --lume-g-spacing-7: 1.25rem;
      --lume-g-spacing-8: 1.5rem;
      --lume-g-spacing-9: 2rem;
      --lume-g-spacing-10: 2.5rem;
      --lume-g-spacing-11: 3rem;
      --lume-g-spacing-12: 3.5rem;
      --lume-g-spacing-13: 4rem;
      --lume-g-spacing-14: 4.5rem;
      --lume-g-spacing-15: 5rem;
  }
/* LUME NORMALIZE */
/*  REM units are based on this value. A percentage value enables user specified default font sizes.
    Legacy style packages may be set at 14px or other values.
*/
html {
  font-size: 100%;
}
body {
  font-family: var(--lume-g-font-family);
  font-size: var(--lume-g-font-size-base);
  line-height: 1.5;
  color: var(--lume-g-color-neutral-40);
}
* {
  box-sizing: border-box;
}
::-moz-focus-inner {
  padding: 0;
  border: 0;
}
/* Separated these 3 any-link pseudo selectors in 3 different section
 * because we cannot use a single section with commas
 */
:-webkit-any-link {
  text-decoration: none;
}
:-moz-any-link {
  text-decoration: none;
}
:any-link {
  text-decoration: none;
}
a {
  text-decoration: none;
  background-color: transparent;
}
b,
strong {
  font-weight: var(--lume-g-font-weight-bold);
}
blockquote {
  color: var(--lume-g-color-neutral-40);
  padding: 10px 20px;
  margin: 10px 0;
  border-left: 3px solid var(--lume-g-color-neutral-65);
}
code {
  font-family: var(--lume-g-font-family-code);
  padding: 2px 4px;
  color: var(--lume-g-color-neutral-40);
  background-color: var(--lume-g-color-neutral-95);
}
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}
fieldset label {
  display: inline-block;
}
fieldset label:first-child {
  margin-top: 0;
}
fieldset legend {
  font-size: var(--lume-g-font-size-base);
  margin: 0 0 10px;
  padding: 0;
  font-weight: 300;
  color: var(--lume-g-color-neutral-40);
  width: 100%;
  border-bottom: 1px solid var(--lume-g-color-neutral-80);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--lume-g-font-family);
  font-weight: var(--lume-g-font-weight-bold);
}
h1 {
  font-size: var(--lume-g-font-scale-7);
}
h2 {
  font-size: var(--lume-g-font-scale-6);
}
h3 {
  font-size: var(--lume-g-font-scale-5);
}
h4 {
  font-size: var(--lume-g-font-scale-4);
}
h5 {
  font-size: var(--lume-g-font-scale-3);
}
h6 {
  font-size: var(--lume-g-font-scale-2);
}
h1,
h2,
h3 {
  margin: 20px 0 10px;
  color: var(--lume-g-color-neutral-30);
}
h4,
h5,
h6,
p {
  margin: 10px 0;
}
hr {
  border: 0;
  border-top: 1px solid var(--lume-g-color-neutral-80);
}
img {
  max-width: 100%;
  height: auto;
  border: 0;
}
pre {
  padding: 9.5px;
  margin: 0 0 10px;
  background-color: var(--lume-g-color-neutral-95);
  border: 1px solid var(--lume-g-color-neutral-70);
  word-break: break-all;
  word-wrap: break-word;
  line-height: 1.42;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
lume-button + lume-button {
  margin-inline-start: var(--lume-c-button-spacing-sibling-inline-start, var(--lume-g-spacing-4));
}
